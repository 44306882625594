import st from './Input.module.scss';
import searchIco from './../Icos/ico-search.svg';
import clockIco from './../Icos/ico-clock.svg';
import {memo} from "react";

type InputType = {
    ico?: 'blank' | 'search' | 'password' | 'clock';
    placeholder?: string;
    value?: any;
    onChange?: (obj: any) => void;
    name?: string;
    hasError?: boolean;
    errorText?: string;
    type?: 'text' | 'password' | 'date' | 'datetime-local';
    compact?: boolean;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    inputValidate?: 'noValidate' | 'onlyNumbers';

}

function Input({
                   ico = 'blank',
                   placeholder = '',
                   value = '',
                   onChange,
                   name = '',
                   hasError = false,
                   type = 'text',
                   errorText = '',
                   compact = false,
                   isReadOnly = false, isDisabled = false,
                   inputValidate = 'noValidate'
               }: InputType) {
    if (value == null) {
        value = '';
    }
    // {console.log(name, value)}
    return (
        <div
            className={st.input + ' ' + ((isDisabled || isReadOnly) ? st.disabled : ' ' + ((hasError) ? st.error : ''))}
            key={"Input" + name}>
            {ico === 'search' ? <img src={searchIco} alt={'search'}/> : ''}
            <input name={name} className={st.editable + ' ' + (compact ? st.compact : '')} placeholder={placeholder}
                   value={value} disabled={isDisabled}
                   type={type}
                   onKeyDown={(e) => {
                       if (inputValidate === 'onlyNumbers') {
                           if (isNaN(Number.parseInt(e.key)) && e.code !== 'Delete' && e.code !== 'Backspace' && e.code !== 'ArrowLeft' && e.code !== 'ArrowRight') {
                               e.stopPropagation();
                               e.preventDefault();
                           }
                       }
                   }}
                   readOnly={isReadOnly}
                   onChange={onChange ? onChange : () => {
                       // console.log('empty')
                   }} key={"RealInput" + name}/>
            {ico === 'clock' ? <img src={clockIco} data-tooltip-id="form-tooltip"
                                    data-tooltip-content="Changes to this field require moderation"
                                    alt={'Changes to this field require moderation'}/> : ''}
            {errorText?.length > 1 &&
                (<span className={st.errorHelp + ' ' + ((hasError) ? st.errorHelpActive : '')}>{errorText}</span>)
            }

        </div>
    );
}

export default memo(Input)
